import { createApp } from "vue";
import "../../public/styles/app.css";

import SvgVue from 'svg-vue3'
import VueLazyLoad from 'vue3-lazyload'

const app = createApp()

app.use(VueLazyLoad)
app.use(SvgVue, {
  svgPath: 'resources/svg',
  extract: false,
  svgoSettings: [
      { removeTitle: true },
      { removeViewBox: false },
      { removeDimensions: true }
  ]
})

app.mount("#app")
